@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
    font-family: 'Roboto', sans-serif;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    /* margin: 0 auto; */
    /* width: max-content; */
    align-items: center;

}

.App {
    text-align: center;
}



.navbarMain {
    background-image: linear-gradient(rgba(249, 184, 24, 0.5), transparent);
    /* border-bottom: solid 15px rgba(200,38,25,0.1); */
    width: 100%;
    height: 180px;
    max-width: 100%;
    max-height: 180px;
    display: flex;
    padding: 0;
    margin-left: auto;
    margin-right: auto;

    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: stretch;
    column-gap: 0.875rem;

}

.boxNavbarMain {
    display: flex;
    width: 100% !important;
    max-width: 80rem;
    margin: 0 auto;
    column-gap: 0.875rem;
    justify-content: space-between;

}

.dropdownNavBar{
position: fixed !important;
max-width: 100vh;
}

.areaAssociados {
    height: 100%;
    padding-top: 0rem;
    /* padding-right: -5rem; */
    /* padding-right: 10rem; */
    margin-right: 3rem;
    text-justify: auto;
    font-family: "Copperplate", "Courier New", monospace;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.7);
    display: flex;
    box-shadow: 0px 0px 10px rgba(179, 31, 20, 1);
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    border-radius: 10rem;
    flex-wrap: nowrap;
}

.areaAssociados:hover {
    color: white;
    background-color: rgba(220, 53, 69, 1);
    box-shadow: 0px 0px 8px rgba(179, 31, 20, 1);
}

.navbarLogo {
    /* max-width: 200px; */
    max-width: 95%;
    max-height: 120px;
    background-color: transparent;
    box-shadow: 0 1px 10px var(--gray-600);
    margin: 0.8rem;
}

.navbarEndereco {
    margin: 1rem;

}

.navbarBtns {
    text-align: right;
    margin-top: 1rem;
    border-radius: 100px;
}


.btnHeader_selected{
    /* box-shadow: 10px 10px 10px rgba(179, 31, 20, 0.8); */
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0;
}

.btnHeader_unSelected {
    box-shadow: 10px 10px 10px rgba(179, 31, 20, 0.8);
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0;
}

.btnHeader {
    box-shadow: 10px 10px 10px rgba(179, 31, 20, 0.8);
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0;
}

.btnHeader:focus {
    box-shadow: 10px 10px 10px rgba(179, 31, 20, 1);
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0;

}


.btnHeaderLink {
    text-decoration: none;
    color: #fff !important;
}


.tituloLojas {
    text-align: left;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-shadow: 0.1rem 0.1rem rgba(249, 185, 24, 0.535);
}

.textoLojas {
    font-size: 2rem;
    text-align: center;

}

.loja {
    background-image: linear-gradient(transparent, rgba(249, 184, 24, 0.5));
    /* background-color: rgba(249, 184, 24, 0.1); */
    box-shadow: 5px 5px 10px rgba(249, 184, 24, 0.5);
    padding: 1rem;
    margin: 2rem auto;
    width: 96vw;
    border-radius: 10px;
    /* max-width: 85rem; */
    max-width: 100%;

    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    column-gap: 0.875rem;
}

.lojaContainer {
    display: flex;
    width: 100%;
    max-width: 80rem;
    margin: 0px 4rem;
    column-gap: 0.875rem;
    justify-content: space-between;

}

.lojaDescricao {
    margin: auto 0;
    background-image: linear-gradient(rgba(249, 184, 24, 0.05), transparent);
    border-radius: 5rem;
    width: 100%;
}

.imagensLogo {
    margin: auto auto;
    width: 20rem;
    /* max-width: 50vh; */
    /* max-height: 13rem; */

}

.nomeLoja {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    /* text-shadow: 0.1rem 0.1rem rgba(249, 185, 24, 0.535); */
    text-shadow: 0.2rem 0.2rem rgba(249, 185, 24, 0.535);

}

.sobreNos {
    margin: 1rem auto;
    width: 96vw;
    border-radius: 10px;
    max-width: 85rem;

    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    column-gap: 0.875rem;

}

.TextoSobreNos {
    font-size: 2rem;
}

.capa {
    max-width: 100%;
    width: 100%;
}

.ImgCapa {
    width: auto;
    max-width: 100%;
    max-height: 80vh;
    margin: 0 auto;
    border-radius: 1rem;
    background-color: rgba(179, 31, 20, 1);
}

.inicialIni {
    margin: 0rem 2rem;
    width: 100%;
    border-radius: 10px;
    max-width: 100%;
    padding-left: 1rem;
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    column-gap: 0.875rem;
    margin: 0 auto;
}

.inicialCarrousel {
    margin-left: 3%;
    /* background-color: red; */
    width: 60%;
    border-radius: 1rem;
    background-color: rgba(179, 31, 20, 1);
    /* border-left: solid 2rem rgba(179,31,20, 1);
    border-right: solid 2rem rgba(179,31,20, 1); */
    box-shadow: 10px 10px 30px rgba(179, 31, 20, 1);
}

.inicialLateral {
    margin-top: 3rem;
    width: 33%;
}

.inicialLogo {
    max-width: 100%;
    width: 70%;
}

.inicialTexto {
    margin-top: 2rem;
}

.slick-prev:before,
.slick-next:before {
    color: rgba(179, 31, 20, 1);
}

.linkLoja,
.linkLoja:hover {
    text-decoration: underline;
    color: black;
    font-size: 1.5rem;
    margin: 0 auto;
    /* background-image: linear-gradient(rgba(249, 184, 24, 0.5), transparent);
    border-radius: 2rem;
    padding: 0.5rem; */
}

.linkLojaEndereco {
    text-decoration: none;
    color: black;
    font-size: 1.6rem;
    margin: 1rem auto;
    /* box-shadow: 10px 10px 30px rgba(249, 185, 24, 1); */
}

.linkLojaEmail {
    text-decoration: none;
    color: black;
    font-size: 1.6rem;
    margin: 1rem auto;
    /* box-shadow: 10px 10px 30px rgba(249, 185, 24, 1); */
}

.icone {
    font-size: 1.8rem;
    color: rgba(179, 31, 20, 1);
}

.lojasContainer {
    width: 100%;
    max-width: 80%;
    text-align: center;
    align-items: center;
    max-width: 100%;
    margin: auto 0;
}

.iconeMenu {
    margin: 0 auto;
    font-size: 3rem;
    
    color: rgba(179, 31, 20, 1);
}

.noContent {
    background-color: transparent;
    border: 0 transparent;
    border-radius: .5rem;
    align-items: center;
    flex-direction: column;
    /* background-color: red; */
}
.cabecalho{
    max-width: 80rem;
    margin: 0 auto;
}


@media screen and (max-width: 1200px) {
    * {
        text-align: center;
        align-items: center;
        max-width: 100%;
        margin: auto 0;
    }

    .boxNavbarMain {
        width: initial !important;    
    }

    .inicialCarrousel {
        margin-left: 3%;
        margin-top: 2rem;
        /* background-color: red; */
        width: 88%;
        border-radius: 1rem;
        background-color: rgba(179, 31, 20, 1);
        /* border-left: solid 2rem rgba(179,31,20, 1);
        border-right: solid 2rem rgba(179,31,20, 1); */
        box-shadow: 10px 10px 30px rgba(179, 31, 20, 1);
    }
    
    .inicialLateral {
        margin-top: 3rem;
        width: 100%;
    }

    .inicialIni {
        margin: 0rem 2rem;
        width: 100%;
        border-radius: 10px;
        max-width: 100%;
        padding-left: 1rem;
        display: block;
        /* position: absolute; */
        flex-wrap: wrap;
        /* justify-content: space-between; */
        /* align-items: stretch; */
        column-gap: 0.875rem;
        margin: 0 auto;
    }

    .navbarMain {
        /* flex-wrap: wrap; */
        justify-content: start !important;

    }

    .btnHeader {
        box-shadow: 10px 10px 10px rgba(179, 31, 20, 0.8);
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        font-size: 1rem;
        border: 0;
    }

    .lojaContainer {
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        margin: 0;

    }

    .lojaDescricao {
        max-width: 100%;
        flex: flex;
        word-break: break-word;
        margin: 0px auto !important;
    }

    .btnHeader:focus {
        box-shadow: 10px 10px 10px rgba(179, 31, 20, 1);
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        font-size: 1rem;
        border: 0;

    }

    .navbarMain {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lojaContainer {
        margin: 0px auto !important;
        align-items: center;
        justify-content: center;
    }

    .tituloLojas {

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cabecalho {
        align-items: center;
        justify-content: center;
        margin: auto 7%;
    }

    .navbarEndereco {
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }

    .btnDropDown {
        font-size: 1.5rem;
        margin-top: 1.5rem;
        flex-direction: column;
        /* position: absolute; */
    }

    .dropdown-menu {
        background: linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(252,222,150,1) 100%);
        border: transparent;
        border-radius: 0.3rem;
        width: 100%;
        height: max-content;
        max-width: 100vh !important;
        margin: 19rem auto;
        padding-bottom: 100vh;
    }

    .dropdown {
        position: relative;
        margin: 0 auto;
        height: 100% !important;
    }
}

@media screen and (max-width: 1012px) {

    .btnHeader {
        box-shadow: 10px 10px 10px rgba(179, 31, 20, 0.8);
        margin: 1rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        border: 0;
        display: none;
        
    }
    
    .areaAssociados {
        display: none;
    }

    .btnHeader:focus {
        box-shadow: 10px 10px 10px rgba(179, 31, 20, 1);
        margin: 0.2rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        border: 0;

    }
}